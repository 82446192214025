.slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;
    font-size: 40px;
    color:#4db649;
    background: transparent;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color:#4db649 !important;
    background: transparent;
  }



  .mid-nav {
    background-color: #f8f8f8;
    padding: 10px;
    width: 100%;
    z-index: 1000;
    position: relative;
    display: flex;
    justify-content: center; /* Center-aligns the buttons */
  }
  
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .nav-button {
    margin: 5px;
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .price-text {
    margin-left: auto; /* Pushes price to the extreme right */
    padding-right: 40px; /* Adjusts space before the dropdown icon */
  }
   
  @media (max-width: 768px) { /* Adjust breakpoint as needed */
    .hide-on-mobile {
      display: none;
    }
  }

  @media (min-width: 768px) { /* Adjust breakpoint as needed */
    .mobile-break {
      display: none;
    }
  }

  .sticky-container {
    width: 300px !important;
  }
 
  