.property-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}
.property-listing .property-info {
    flex-basis: 60%;
}
.property-listing .property-price {
    flex-basis: 40%;
    text-align: right;
}
.property-listing .property-price .price {
    font-size: 24px;
    font-weight: bold;
}
.property-listing .property-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.property-listing .property-info ul li {
    margin-bottom: 10px;
}
.property-listing .property-info ul li span {
    font-weight: bold;
}


@media (min-width: 768px) {

 
    #list-tab {
      display: flex;
      flex-direction: row;
     
    }
    .list-group-item {
      flex: 1;
      text-align: center;
      margin-right: 10px; /* Add margin to create space between tabs */
    }
    .list-group-item:last-child {
      margin-right: 0; /* Remove margin from the last item */
    }
  }
  
  @media (max-width: 767.98px) {


    #list-tab {
      display: block;
    }

   
  }


 

  @media (min-width: 1000px) {


    .data{

 

       
        margin-left: 5% !important;
        margin-right: 3% !important;
        
        
    }

   
  }
  
 

  @media (min-width: 1000px) {


    .marlaplot{

 

       
        margin-top: 100px !important;
        
        
        
    }

   
  }