/* Custom CSS for responsive styles */
@media (max-width: 767.98px) {
    .text-center h1 {
      font-size: 30px;
    }
  
    .dropdown {
      width: 100%;
    }
  
    .dropdown-menu {
      width: 100%;
    }
  
    .rounded-container .select-container {
      flex-direction: column;
    }
  
    .rounded-container .select-container > div {
      margin-bottom: 10px;
    }

    .mobile{
        width: 100% !important;
         height: "10vh"
    }
    .home-text{

        font-size: 20px !important;

    }

    .d-flex-1 {
      flex-direction: row !important;
      gap: 5px;
      margin-top: -70px;

    }
   
  
    .my-button, 
    .btn-secondary {
      width: 100%;
      padding: 10px 0;
    }
    .background-video {
      height: 95%; 
    }
  }
  
 /* General button styles for consistency */
.slider_num button {
  width: auto;
  margin: 0;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .d-flex {
    gap: 10px; /* Consistent spacing between buttons */
  }

  .invest-button {
    width: 90%; /* Full width for the "Invest" button */
    margin-top: 10px; /* Space below the "Buy" and "Rent" buttons */
  }

  .invest-button button {
    width: 90%; /* Ensures the "Invest" button spans the full width */
    text-align: center;
  }
}