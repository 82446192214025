.capital_city{
    background-image: url('../../assets/imagenew/Capital-smart-city-1-1.webp');
    width: 100%;
     height: 50vh !important ;
     justify-content: center;
     align-items: center;

}

.capital_city h1{
    color: #fff !important;
    font-size: 24px !important;
}