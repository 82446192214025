/* Add this CSS to your stylesheet */
 
  
  
  
  .card-img-top {
    width: 100%;
   
    object-fit: cover; /* Ensures image covers the area */
  }
  
 
  
 
  
 
  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card-body {
    flex-grow: 1;
  }
  
   
  