.ad {
    margin: 0px auto;
    /* max-width: 768px; */
    background-image: url('/src/assets/images/myadv.jpg');
    background-size: cover;
    background-position: left center;
    border: 1px solid #333;

    .container {
        clear: both;
        padding: 1px 0 0 0;

        .logo {
            width: 283px;
            height: 50px;
            margin: 20px auto;
            background-image: url('http://www.seancrater.com/codepen/timb2.png');
            background-size: cover;
            background-position: center center;
        }

        a {
            display: block;
            color: #ffffff;
            text-decoration: none;
        }
    }

    h2 {
        background-color: rgba(245, 160, 25, .8);
        padding: 10px 20px;
        font-size: 2.15em;
        line-height: 70px;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        transition: all .15s ease;
        text-align: center;

        &:hover {
            background-color: rgba(245, 160, 25, .8);
            transition: all .15s ease;
        }
    }
}

@media screen and (min-width: 525px) {
    .ad {
        height: 90px;

        .container {
            padding: 0 25px;
        }

        .logo {
            float: left;
        }

        h2 {
            float: right;
            background-color: rgba(255, 255, 255, .25);
        }
    }
}