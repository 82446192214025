.suggestion-list {
    position: absolute;
    background-color: white; /* White background */
    color: black; /* Black text */
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc; /* Optional: Adds a border to the dropdown */
    width: 47.6%; /* Matches the width of the input */
    z-index: 9999; /* Ensures dropdown appears above other elements */
    max-height: 200px; /* Optional: Limit height for better UX */
    overflow-y: auto; /* Adds scroll if content exceeds max-height */
    text-align: left; /* Aligns text to the left */
  }
  
  .suggestion-item {
    padding: 10px; /* Adds spacing for better readability */
    cursor: pointer; /* Shows a pointer cursor on hover */
    text-align: left; /* Ensures text is aligned to the left */
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0; /* Optional: Light gray background on hover */
  }
  
  @media (max-width: 767px) {
    .hide-on-mobile {
      display: none;
    }
  }
  