/* Styles for the thumbnail dots */
.slick-dots.slick-thumb {
    display: flex !important;
    justify-content: start;
    order: -1; /* Move to the start */
    padding: 0;
  margin: 0;
  margin-left: -70px;
     
  }
  
  .slick-dots.slick-thumb li {
    list-style: none;
    margin: 0 70px ;/* Adjust this value to increase/decrease spacing */
   
  }
  
  .slick-dots.slick-thumb li a {
    display: block;
  }
  
  .slick-dots.slick-thumb li img {
    width: 150px; /* Thumbnail width */
    height: 90px; /* Thumbnail height */
    border-radius: 5px; /* Rounded corners */
   
  }
  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;
    font-size: 40px;
    color:#4db649;
    background: transparent;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color:#4db649 !important;
    background: transparent;
  }


 
  @media (max-width: 768px) {
    .slick-dots.slick-thumb {
      display: none !important;
    }
  }


  @media (max-width: 768px) {
    .navbar-text {
      display: none;
    }
  }