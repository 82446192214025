html,body{
  overflow-x: hidden !important;
}


.menu_color{

color: white;
}
 

.bg_makkans{
	background: #4DB749;
}
.bg_makkan{
	background: #4DB749;
}
.bg_color_maken{
	color: #4DB749;
}

.bg_makkan:hover {
  background-color: rgba(0,0,0,0.3);
}

.App {
 text-align: center;
}
.fw-normal {
  font-size: 16px !important;
}

h1{
  font-size: 1.5rem !important;
  font-weight: bold;
}

h3{
  font-size: 1rem !important;
  font-weight: bold !important;
}

h5{
  font-size: 1rem !important;
  font-weight: 600 !important;
}
 

.home_text::-webkit-scrollbar {
  display: none;
}

 
.dropdown-item:hover{
 
  color: #fff !important;
  font-size: 13 !important;
}

.AgencySlider_card{
  position: relative !important;
  /* z-index: -1 !important; */
}
p{

  font-family: lato;
  font-size: 15px;
  color:#707070;
 line-height: "1.5em";
}
.fw-bold{

  font-size: 16px;
  
  font-family: lato;
}



select{
  height: 50px;
}

button{
  height: 46px !important;
}


.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  z-index: 99999999999999999999999999;
}


.slider_main{
  overflow-x: scroll !important;
  width: 100% !important;
}


.slider_main::-webkit-scrollbar {
  display: none;
}

.dropcity p{
  margin: 0px;
  padding: 5px 5px;
  z-index: 9999999999999999999999999;
}

.dropcity{
  z-index: 9999999999999999999999999;
}

.dropcity p:hover{
  background-color: rgb(31, 31, 31);
  display: block;
}


.mycontentbank:hover .dropcity{
  visibility: visible;
}


.btn{
   font-size: 14px !important;
   font-weight: bold !important;
}



.btn-outline-success{
  color: #4DB749 !important;
  border-color: #4DB749 !important;
}

.btn-outline-success:hover{
  color: #fff !important;
  border-color: #4DB749 !important;
  background-color: #4DB749 !important;
}
a{
  text-decoration: none !important;
}

.view_property i{
  color: #4DB749 !important;
  font-size: 25px;
  z-index: 1000000;
}


input:focus{
   box-shadow: unset !important;
 


}

.top_menu{
  list-style: none !important;
}

select{
  color:#7D7D7D !important;
  border-radius: 2px !important;
}

select:focus{
  box-shadow: unset !important;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.formimg{
  background-image: url('./1.png') !important;
  width: 100% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  
}

.add_location label{
  color: #5a5a5a !important;
}

.add_location .location input,
.add_location .location select{
  background-color: rgba(255, 255, 255, 1.5) !important;  
  backdrop-filter: blur(5px) !important;
  height: 45px !important;
  box-shadow: 0 1px 5px 0px rgba(204, 204, 204,3.3)!important;
}

.location_btn{

  background-color: rgba(255, 255, 255, 1.5) !important;  
  backdrop-filter: blur(5px) !important;
  box-shadow: 0 1px 5px 0px rgba(204, 204, 204,3.3)!important;
}

.location_btn:hover{
  color: #4DB749 !important;
}


.features_card .house_price{
  color: #4DB749 !important;
}




.mySwiper1 .swiper-pagination{
  position: absolute !important;
  bottom: 15px !important;
}

.mySwiper1 .swiper-pagination-bullet-active {
  background: #fff !important;
}

 

.mt-5{

  margin-top: 20em;
}




.Signup_card label{
  color: #5A5A5A !important;
  font-weight: 600 !important;
}

body{
  overflow-x: hidden !important;
}


#navbar{

  backdrop-filter: blur(15px) !important;
  --webkit-backdrop-filter: blur(15px) !important;
  background-color:rgba(255, 255, 255, 0.8)  !important;

}



#navbar ul li a:hover{

  color: #4DB749 !important;
  /* background-color: white !important;
  border: 1px solid #4DB749 !important; */

}


.myagency:hover img{
  border:2px solid #4DB749 !important;

}


img{
  object-fit: cover;
}


.phead:hover{
  color: white !important;
}


.slider_home2{
  backdrop-filter: blur(15px) !important;
  --webkit-backdrop-filter: blur(15px) !important;
  background-color: rgba(139, 139, 136, 0.7) !important;
}



 .mySwiper10 .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

  .mySwiper10 .swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper10 .swiper-slide img{
  height: 360px !important;
  width: 100% !important;
}

.mySwiper10 {
  height: 80%;
  width: 100%;
}

.mySwiper11 {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper11 .swiper-slide {
  width: 25%;
  height: 100px;
  opacity: 0.4;
}

.mySwiper11 .swiper-slide-thumb-active {
  opacity: 1;
}

 .mySwiper11 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-initialized{
  overflow: visible !important;
}


.mySwiper20 .swiper-button-next{
  right: var(--swiper-navigation-sides-offset,-42px) !important;
}

.mySwiper20 .swiper-button-prev{
  left: var(--swiper-navigation-sides-offset,-42px) !important;
}



#navbar .navbar-nav .nav-link {
   font-weight: 600 !important;
}


/* profess */

.professional{
  background-image:linear-gradient(rgba(200,206,207,0.4),rgba(200,206,207,0.4)),url('./assets/images/contact\ page.jpg');
  width: 100%;
  height: auto !important;
  background-position: center;
  background-size: cover;
  opacity: 0.8 !important;

}

.professional h1{
  color: #000 !important;
  font-size: 47px !important;
}

.addvertise{
  background-image:url('./assets/images/myadv.jpg');
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 50vh !important;
   border-color:rgba(137, 155, 167, 0.8) !important;
  backdrop-filter: blur(15px);
}
.addvertise2{
  background-image:linear-gradient(rgba(255, 255, 255,0.8),rgba(255, 255, 255, 0.8)),url('./assets/images/professinoal.jpg');
  width: 100%;
  background-position: center;
  height: 60vh !important ;
  background-size: cover;
   border-color:rgba(137, 155, 167, 0.6) !important;
  backdrop-filter: blur(15px);
}


.addvertise2 h1{
  color: #000 !important;
  font-size: 50px !important;
}

.addvertise2 button{
  font-weight: 300 !important;
}

.addvertise h1{
  color: #fff !important;
}





.btn-primary{
  background-color: #4DB749 !important; 
  border:1px solid #4DB749 !important;
}

.footer ul{
  list-style: none !important;
}

.social_media ul li{
  padding: 6px;
}

.footer h6{
  color: #7D7D7D !important;
}

.footer h5{
  color: #7D7D7D !important;
}
.footer p{
  font-size: 13px !important;
}

.footer h3{
  color: #4DB749 !important;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chairMan p{
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 19px !important;
}

.flip-btm{
  position: relative !important;
  transition: 0.5s !important;
}


.flip-btm img
{
	display: block;
	width: 100%;
	height: 300px;
}
.flip-btm .flipBtmDiv
{
	position: absolute;
	right: 0px;
	left: 0px;
	bottom: 0px;
	top: 70%;
	 backdrop-filter: blur(15px) !important;
  --webkit-backdrop-filter: blur(15px) !important;
  background-color:rgba(139, 139, 136, 0.6) !important;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateX(90deg);
	-webkit-transform: rotateX(90deg);
	-moz-transform: perspective(300px)  rotateX(90deg);
	-o-transform: rotateX(90deg);
	    transform-origin: 50% 100% 0;
	-webkit-transition: 0.5s;
}
.flip-btm .flipBtmDiv img
{
	display: block;
	width: 40%;
	height: auto;
	margin: 0 auto;
	vertical-align: middle;
}
.flip-btm:hover .flipBtmDiv
{
	-webkit-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
}

.flip_file{
  transition: 0.5s !important;
}

.flip-btm:hover  .flip_file{
  transform: scale(1.2) !important;
  transition: 0.5s;
}

#carouselExampleCaptions .carousel-indicators button{
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;

}


.fire_logo{
  position: absolute !important;
  top: 10px !important;
  left: 14px !important;
}

.heart_logo{
  position: absolute !important;
  right: 14px !important;
  top: 10px !important;
}




.swiper-pagination{
   position: unset !important;
   margin-top: 30px !important;
}





.swiper-pagination-bullet{
  width: 10px !important;
  height: 10px !important; 
 
  border-color: 2px solid #7D7D7D !important;
}


 
.swiper-pagination-bullet-active {
   background-color: #7D7D7D !important;
}

.swiper-button-next{
   width: 50px !important;
   height: 50px !important;
   background-color: #fff !important;
   border-radius: 50% !important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

}

.swiper-button-prev{
  width: 50px !important;
  height: 50px !important;
  background-color: #fff !important;
  border-radius: 50% !important;
   box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}


.swiper-button-next::after,
.swiper-button-prev::after
{
  display: none !important;
}

.swiper-button-next{
  content: '';
  background-image: url('./assets/images/right.png') !important;
  background: bottom;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: center;
    width: 50px !important;

}

.swiper-button-prev{
  content: '';
  background-image: url('./assets/images/left.png') !important;
  /* background: bottom; */
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: center;
    
    width:50px !important;
}



/* features */

.features .features_img img {
  transition:0.5s !important;
}
.features{
  box-shadow: 0 4px 12px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.features .features_img img:hover{
  transform:scale(1.3) !important;
}


.agencies{
  background-image:linear-gradient(rgba(255, 255, 255,0.6),rgba(255, 255, 255, 0.6)),url('./assets/images/contactus.jpeg');
  width: 100%;
  background-position: center;
  background-size: cover;
}

.about_us{
  background-image: url('./assets/images/About\ us\ banner.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position:center center;
  height: 45vh;
}

.career_us{
  
 
  background-image: url('./assets/images/Career\ page.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center center;
  height: 45vh;
}
.Our_team{
  
 
  background-image: url('./assets/images/our\ team.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center center;
  height: 45vh;
}

.about_us3{
  background-image: url('./assets/images/about_us.jpeg');
  background-size: cover;
  background-position: center;
  height: 40vh !important;
}

.about_us2{
  background-image: url('./assets/images/about_us.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh !important;
}

.agencies label{
  color: #5A5A5A !important;
  font-weight: 500;
}
.agencies input::placeholder{
  color: #DDDDDD !important;
}



.home_slider .accordion-flush{
  --bs-accordion-bg:transparent !important;
}


.accordion-button{
  box-shadow: none !important;
}

.home_slider  .accordion-button::after {
  display: none !important;
}


 .home_slider .accordion-button:not(.collapsed){
  background-color: transparent !important;
  box-shadow: none !important;
}



/* pharmacy  */




.five {
  list-style: none;
  padding: 0;
}
.five > li {
  background: #FFFFFF;
  border: 1px solid rgba(150,150,150,0.29);
  -moz-box-shadow: 0px 0px 18px 0px rgba(103,85,85,0.39);
  box-shadow: 0px 0px 18px 0px rgba(103,85,85,0.39);
  width: 235px;
  height: 350px;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}
.five > li img {
  margin-top: -5px;
}
.five > li:hover img {
  margin-top: 30px;
}
.five > li img.more-about {
  left: 103px;
  padding: 1px;
  position: absolute;
  width: 45px;
  z-index: 1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  bottom: 50px;
}
.five > li:hover {
  -moz-box-shadow: 0px 0px 5px 0px rgba(103,85,85,0.25);
  box-shadow: 0px 0px 5px 0px rgba(103,85,85,0.25);
}
.five > li:hover span {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  top: -300px;
  background: #4DB749;
}
.five > li:hover span p {
  color: white;
  font-size: 14px;
}
.five > li span .text-wrapper {
  opacity: 0;
}
.five > li:hover span .text-wrapper {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 200px;
  opacity: 1;
}
.five > li:hover .social {
  margin-top: 250px;
}
.five > li:hover .wrapper > img {
  display: none;
}
.five > li:hover h3 {
  color: #4DB749;
  margin-top: -105px;
}
.five > li .wrapper {
  overflow: hidden;
  position: absolute;
  width: 235px;
  height: 350px;
}
.five > li span {
  display: block;
  height: 500px;
  left: -125px;
  position: absolute;
  top: -490px;
  width: 500px;
  background: #4DB749;
}
.five .mamber-img {
  width: 100px;
}

.five >li h3{
  font-size: 25px !important;
}
.five > li h3 em {
  display: block;
  padding-left:10px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
 margin-top: 5px  color #4DB749;
}
.five .social {
  list-style: none;
  text-align: center;
  margin: 10px 0 0 0;
  padding: 10px;
}
.five .social li {
  opacity: 0;
  display: inline-block;
  padding: 5px;
}
.five > li:hover .social li {
  opacity: 0.5;
}
.five .social li a {
  cursor: pointer;
  display: block;
}
.five .social li a:hover {
  opacity: 0.5;
}
.five .social li img {
  width: 24px;
}
.five > li:hover .social li a {
  -webkit-animation: social-show 0.5s 1;
  -moz-animation: social-show 0.5s 1;
  -o-animation: social-show 0.5s 1;
  animation: social-show 0.5s 1;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}
.five .social li img {
  width: 24px;
}
 @-webkit-keyframes social-show {
 from {
margin-top: 140px;
}
to {
  margin-top: 0px;
}
}
 @-moz-keyframes social-show {
 from {
margin-top: 140px;
}
to {
  margin-top: 0px;
}
}
 @-o-keyframes social-show {
 from {
margin-top: 140px;
}
to {
  margin-top: 0px;
}
}
 @keyframes social-show {
 from {
margin-top: 140px;
}
to {
  margin-top: 0px;
}
 }


.five .transition {
  transition: .5s cubic-bezier(.3, 0, 0, 1.3)
}
/* --

@-webkit-keyframes arrow {
  0% {
 background-position: center top;
 }
  50% {
 background-position: center 10px;
 }
  100% {
 background-position: center top;
 }
 }
  @-moz-keyframes arrow {
  0% {
 background-position: center top;
 }
  50% {
 background-position: center 10px;
 }
  100% {
 background-position: center top;
 }
 }
  @keyframes arrow {
  0% {
 background-position: center top;
 }
  50% {
 background-position: center 10px;
 }
  100% {
 background-position: center top;
 }
 }
/* --------- FOUR --------- */ 


@media screen and (min-width:1080px){
  
}


@media screen and (max-width:768px) {

  .home_slider{
    height: auto !important;
    padding-bottom: 40px !important;
    margin-top:100px !important;
  }

 

  #home_slider_content{
    height: auto !important;
  }


  
}

@media screen and (max-width:500px) {

  h1{
    font-size: 30px;
   }



  .card_row{
    right: 0 !important;
  }
  

  .search_transparent{
    display: none !important;
  }
  .search_transparent input{
    outline:none !important;
  }



  .swiper-button-next,
.swiper-button-prev
{
  display: none !important;
  
}


  
}






*{
  margin:0;
  padding:0;
  text-decoration: none;
}

body{
  background-color: #F3F7F9 !important;
  overflow-x: hidden !important;
}
.container2{
  display:flex;
}
main{
  width: 100%;
}
.sidebar{
  background: #fff !important;
  color: black;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link{
  display: flex;
  color:#4DB749;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none !important;
}
.link:hover{
  background:#4DB749;
  color: #fff !important;
  transition: all 0.5s;
}
.container2 .active{
  background: #4DB749;
  color: #fff !important;
}
.icon, .link_text{
  font-size: 16px;
}




h4{
  color: #4DB749 !important;
}


.btn-outline{
   border: 1px solid #4DB749 !important;
   background-color: transparent !important;
   color: #4DB749 !important;
}

.bg-color{
  background-color: #ffffff !important;
}

.search_transparent input::placeholder{
  color: #fff !important;
  font-size: 13px !important;
}


ul{
  list-style: none !important;
}



/* user_setting */

.user_setting .nav-pills .nav-link{
  color: black !important;
  text-align: left !important;
}
.user_setting .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #4DB749 !important;
  background-color: transparent !important;
}




.user_setting input{
  background-color: rgba(255, 255, 255, 1.5) !important;  
  backdrop-filter: blur(5px) !important;
  height: 45px !important;
  box-shadow: 0 1px 5px 0px rgba(204, 204, 204,3.3)!important;
}

.property_management input{
  background-color: rgba(255, 255, 255, 1.5) !important;  
  backdrop-filter: blur(5px) !important;
  height: 45px !important;
  box-shadow: 0 1px 5px 0px rgba(204, 204, 204,3.3)!important; 
}

.property_management  .nav-pills .nav-link {
  background-color: rgba(255, 255, 255, 1.5) !important;  
  backdrop-filter: blur(5px) !important;
  height: 45px !important;
  box-shadow: 0 1px 5px 0px rgba(204, 204, 204,3.3)!important; 
  color: #000  !important;
}


 
.property_management  .nav-pills .nav-link.active {
   color: #4DB749 !important;
}


.top_menu{
  display: none;
}


@media screen and (max-width:768px){

  .professional h1 {
     font-size: 40px !important;
  }

  h1{
   font-size:  20px !important;
  }

  
  

  .sidebar{
    display: none !important;
  }

  .top_menu{
    display: block;
  }
  .search_transparent{
    display: none !important;
  }

  #features_cards{
    flex-wrap: wrap !important;
  }
}




.Toastify__toast-container {
  z-index: 999999999999999999999999999999 !important;
}


.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it's behind your content */
}

.background-video video {
  width: 100%;
  height:100%;
  object-fit: cover; /* Ensure the video covers the entire background */
}


.carousel-item-padding-40-px {
  padding: 0 10px; /* Adjust this value as needed for desired spacing */
}

.carousel-card {
  margin: 0 10px; /* Adjust this value as needed for desired spacing */
}

.carousel-item-padding-40-px {
  padding: 0 10px; /* Adjust the padding as needed */
}



/* Feature propery css */
.features-section .section-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}

.features-section .section-subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.features-section .section-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}

.property-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-card .card-body {
  padding: 0px;
  padding-left: 30px;
  padding-top: 9px;
}

.property-card .status-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 14.4px;
}

.property-card .status-label.available {
  color: green;
}

.property-card .status-label.for-sale {
  color: blue;
}

.property-card .status-label.for-rent {
  color: orange;
}

.property-card .status-label.on-market {
  color: red;
}

.property-card .card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}

.property-card .text-muted {
  font-size: 14px;
  line-height: 16.8px;
}

.property-card .property-details {
  list-style: none;
  padding-left: 0px;
}

.property-card .property-details li {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.6px;
  margin-bottom: 5px;
}

.property-card .contact-icons {
  display: flex;
  justify-content: flex-end;
}

.property-card .contact-icon {
  padding: 0 10px;
  font-size: 20px;
}

.property-card .price {
  float: left;
  padding: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
}

.read-more-container {
  font-family: Arial, sans-serif;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.read-more-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.more-text {
  display: block;
  margin-top: 10px;
}

.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  color: #4db749;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s ease;
}

.read-more-btn:hover {
  color: black;
}


.card {
  margin: 10px; /* Adjust margin as needed */
}


.bg_color_maken{

  color: #4db749;
}


.card {
  transition: all 0.3s ease;
}
.card:hover {
  box-shadow: 0 0 15px rgba(0, 128, 0, 0.7);
  border: 1px solid green;
}

#enquiryDiv.fixed {
  position: fixed;
  top: 20px; /* Adjust this value to the desired fixed position from the top */
  width: inherit; /* Ensure the width stays the same */
}
.sticky-enquiry-form {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Adjust this value to control the distance from the top */
  z-index: 1000; /* Ensure it's above other content */
}

.my-button {
  border: none;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.my-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.rounded-container {
  border-radius: 0.375rem;
}
 
.price-popup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensures it overlaps other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
}

.sticky-nav {
  position: sticky;
    top: 0;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #000000 !important;
  background-color: #f8f9fa !important;
  border-bottom: 3px solid #ffffff !important;
  border-radius: 3px !important;
}

.sticky-container {
  
  position: sticky;
  top:0px;/* Adjust as needed */
  z-index: 99999;
  
}

.slick-prev, .slick-next {
  width: 50px;
  height: 50px;
  z-index: 2;
  font-size: 40px;
  color:#4db649;
}

.slick-prev:before, .slick-next:before {
  font-size: 40px;
  color:#4db649;
}


@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.blink {
  animation: blink 1s infinite;
}
 
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color:#4db649 !important;
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item+.list-group-item:hover{

  background-color:#4db649 !important;
  color: var(--bs-list-group-active-color);
}


 
.slick-dots.slick-thumb {
    display: flex !important;
    justify-content: start;
    order: -1; /* Move to the start */
    padding: 0;
  margin: 0;
  margin-left: -70px;
     
  }
  
  .slick-dots.slick-thumb li {
    list-style: none;
    margin: 0 70px ;/* Adjust this value to increase/decrease spacing */
   
  }
  
  .slick-dots.slick-thumb li a {
    display: block;
  }
  
  .slick-dots.slick-thumb li img {
    width: 150px; /* Thumbnail width */
    height: 90px; /* Thumbnail height */
    border-radius: 5px; /* Rounded corners */
   
  }
  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;
    font-size: 40px;
    color:#4db649;
    background: transparent;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color:#4db649 !important;
    background: transparent;
  }


 
  @media (max-width: 768px) {
    .slick-dots.slick-thumb {
      display: none !important;
    }
  }



  .vertical-header {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    vertical-align: middle;
    text-align: left;
    height: 200px; /* Adjust as needed */
}

.list-item-custom {
  display: flex;
  justify-content: space-around;
   
  
}

.list-item-custom > div {
  text-align: left;

  flex: 1;
  
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
 
  margin-top: 10px; /* Adjust as needed */
}



@media only screen and (max-width: 600px) {
  .imagedata {
  display: none;
  }
}

.carousel-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-container h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

/* .reel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  transition: transform 0.3s ease;
 
  border-radius: 10px;
 
}

.reel:hover {
  transform: scale(1.05);
}

.reel img {
  width: 100%;
  height: 200px;
  max-width: 300px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.reel a {
  margin-top: 20px;
  text-decoration: none;
  color: #4db749;
  font-weight: bold;
  font-size: 12px;
  background-color:#4db749;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.reel a:hover {
  background-color: #4db749;
} */



.reels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reel {
  margin: 10px;
  width: 300px;
}

.reel video {
  width: 100%;
  height: auto;
}


.mid-nav {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap; /* Prevents wrapping of buttons */
}

.nav-buttons-container {
  display: flex; ;
  justify-content: center; /* Center-align the buttons horizontally */
  align-items: center;     /* Center-align the buttons vertically (if necessary) */
  gap: 10px; 
}

.nav-button {
  display: inline-block;
  padding: 10px 15px;
  margin: 0 5px;
  white-space: nowrap; /* Prevents wrapping within buttons */
}

/* Optional: Add some styles for better UX */
.mid-nav::-webkit-scrollbar {
  height: 5px;
}

.mid-nav::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.mid-nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}






/* General Styles */


.timeline {
  display: flex;
  align-items: left
  ;
  justify-content: left;
  overflow: hidden; /* Hide scrollbar */
  padding: 10px;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.timeline-stage {
  display: flex;
  align-items: center;
}

.circle {
  width: 70px; /* Increased width */
  height: 70px; /* Increased height */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  margin: 0 5px;
  font-size: 12px; /* Increased font size */
  text-align: center;
}

.circle.active {
  background-color: #4db749;
  color: white;
}

.line {
  width: 50px;
  height: 2px;
  background-color: #4db749;
}

.media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-content-wrapper {
  margin-top: 20px;
}
.media-content{

  width: 50%;
  border-radius: 20px;
  cursor: pointer;
}

.media-label {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Arrow Button Styles */
.arrow-btn {
  background-color: #4db749;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 10px 10px;
}

.arrow-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.arrow-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(77, 183, 73, 0.5);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .circle {
    width: 60px; /* Adjusted width for smaller screens */
    height: 60px; /* Adjusted height for smaller screens */
    font-size: 12px; /* Adjusted font size for smaller screens */
  }

  .line {
    width: 30px;
  
  }

  .arrow-btn {
    font-size: 12px;
    padding: 5px;
  }

  .media-content {
    width: 100%;
    height: auto;
  }

  .media-content-wrapper {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .timeline {
    flex-direction: row; /* Ensure horizontal direction on mobile */
    justify-content: flex-start; /* Align items to the start */
  }

  .circle,
  .arrow-btn {
    margin: 5px 0;
  }

  .line {
    display: none;
  }
}

/* Display on screens 768px and larger (laptops, desktops) */
@media (min-width: 768px) {
  #midNav{
    display: block;
  }
}

.imagedata {
  display: none;
}

/* Display on screens 768px and larger (laptops, desktops) */
@media (min-width: 768px) {
  .imagedata {
    display: block;
  }
}

@media (max-width: 767px) {
  .img-fluid {
    width: 15em;
    height: auto;
  }
}

/* src/components/FixedBar.css */

.fixed-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
}

.fixed-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  border: none;
  background-color: #4db749;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fixed-button:hover {
  background-color: #4db749;
}

@media (min-width: 768px) {
  .fixed-bar {
    display: none; /* Hide the fixed bar on larger screens */
  }
}


@media (max-width: 768px) {
  .mobileresponsive {
    display: none; /* Hide on screens smaller than 768px */
  }
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 200px; /* Adjust the width as needed */
}

.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: scrollText 10s linear infinite;
  color: white;
  font-size: 14px;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .navbar-text {
    display: none !important;
  }
}


.custom-list {
  list-style: none;
  padding: 0;
}

.custom-list li {
  position: relative;
  padding-left: 25px; /* Adjust spacing */
}

.custom-list li::before {
  content: "●"; /* Small circle icon */
  font-size: 12px; /* Adjust size */
  color: #4db749; /* Adjust color */
  position: absolute;
  left: 0;
  top: 3px; /* Adjust alignment */
}

